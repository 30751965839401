import React from "react"
import { text } from "./text"

export function PageFooter() {
  return (
    <div className="bg-primary  text-white text-center pb-12">
      <img
        src={"image/logo.webp"}
        style={{ height: "120px" }}
        className="mx-auto p-0 m-0"
      />
      <div>
        {text({
          en: "16/6 Alley 1. Phakong Road, Naiwiang, Muangm Nan 55000 Thailand",
          th: "16/6 ซอย 1 ถนนผากอง ตำบลในเวียง อำเภอเมือง จังหวัดน่าน 55000",
        })}
      </div>
    </div>
  )
}
