/* eslint-disable jsx-a11y/alt-text */
import React from "react"
import { text } from "./text"
import { lineLink } from "./App"
import FacebookIcon from "@mui/icons-material/Facebook"
import LocalPhoneIcon from "@mui/icons-material/LocalPhone"
export function contact() {
  return (
    <div className="text-white bg-soft-primary p-5">
      <div className="text-3xl text-center p-8 pb-12">
        {text({ en: "Contact", th: "ติดต่อ" })}
      </div>
      <div className="grid lg:grid-cols-3 grid-cols-1 gap-4 items-center ">
        <div className="mx-auto h-full w-full text-center ">
          <div className="text-xl p-4">
            <LocalPhoneIcon style={{ fontSize: "800%" }} />
          </div>
          <div className="text-xl p-4">
            <a href="tel:0932842707">0932842707</a>
          </div>
        </div>

        <div className="mx-auto text-center h-full w-full  ">
          <div className="text-xl p-4">
            <img
              style={{ width: "40%" }}
              className=" mx-auto rounded-2xl h-auto text-xl p-4 "
              src="image/line.png"
            />
          </div>
          <div className="text-xl p-4">
            <a href={lineLink} target="_blank" rel="noreferrer">
              @NAN0932842707{" "}
            </a>
          </div>
        </div>

        <div className="mx-auto  h-full  w-full text-center ">
          <div className="text-xl p-4">
            <FacebookIcon style={{ fontSize: "800%" }} />
          </div>
          <div className="text-xl p-4">
            <a
              href="https://www.facebook.com/nannakarahotel"
              target="_blank"
              rel="noreferrer"
            >
              Nan Nakara Hotel
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
