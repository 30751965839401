/* eslint-disable jsx-a11y/alt-text */
import { lineLink } from "./App"
import { text } from "./text"

export function quickCallButton() {
  return (
    <div className="h-16 fixed bottom-0 bg-soft-primary text-white w-full text-center left-0 right-0 lg:hidden">
      <div className="grid grid-rows-1 grid-flow-col gap-4 ">
        <div className=" text-2xl py-4 flex items-center border-r-2 ">
          <p className="mx-auto">
            <a href="tel:0932842707">093-284-2707</a>
          </p>
        </div>
        <div className="  flex items-center text-center ">
          <a href={lineLink} className="justify-center mx-auto ">
            <img
              src="image/LINE_Brand_icon.png"
              className="h-10 mx-auto inline-block pr-3"
            />{" "}
            <p className="inline-block align-middle text-xl">
              {" "}
              {text({ en: "Add Friend", th: "เพิ่มเพื่อน" })}
            </p>
          </a>
        </div>
      </div>
    </div>
  )
}
