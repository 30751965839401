/* eslint-disable jsx-a11y/alt-text */
import React from "react"
import { text } from "./text"

export function termAndCondition() {
  return (
    <div className="bg-primary text-white ">
      <div className="grid sm:grid-cols-2">
        <div
          className=" h-auto"
          style={{
            backgroundImage: "url('./optimizeImage/_DSC0205-36.jpeg')",
            aspectRatio: "auto",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            // backgroundAttachment: "fixed",
          }}
        >
          {/* <img className="w-full" src="./optimizeImage/_DSC0205-36.jpeg" /> */}
        </div>
        <div className="lg:p-16 p-8 text-left  ">
          <div className="text-3xl pb-8 ">
            {text({ en: "Term and Condition", th: "เงื่อนไข" })}
          </div>
          <ul className="list-disc text-lg lg:pl-8 p-4">
            <li className="mb-4">
              {text({
                en: "Booking are confirmed if the required deposit is received within 3 days after of booking date",
                th: "โรงแรมจะยืนยันการจองห้องพัก เมื่อมีการโอนเงินค่ามัดจำห้องพักภายใน 3 วันนับจากวันที่จอง เท่านั้น",
              })}
            </li>
            <li className="mb-4">
              {text({
                en: "A 50% refund will be made for cancellations received 30 days before date of arrival,No refund thereafter",
                th: "การยกเลิกการจองก่อน 30 วัน(นับจากวันที่เข้าพัก) ทางโรงแรมจะคืนค่ามัดจำห้องพัก 50% จากยอดโอนค่ามัดจำ",
              })}
            </li>
            <li className="mb-4">
              {text({
                en: "Any cancellation received within 30 days prior to check-in date will incur the full period charge Failure to arrive at hotel will be treated as a  No-Show and Deposits are non-refundable",
                th: "การยกเลิกการจองน้อยกว่า 30 วัน(นับจากวันเข้าพัก) หรือ การจองห้องแล้วไม่เข้าพัก ทางโรงแรมจะไม่คืนเงินค่ามัดจำ ไม่ว่ากรณีใดๆทั้งสิ้น",
              })}
            </li>
            <li className="mb-4">
              {text({
                en: "Please Check the Cancellation Policy Before making advance deposits",
                th: "กรุณาตรวจสอบเงื่อนไขการจองก่อนการโอนค่ามัดจำห้องพัก",
              })}
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
