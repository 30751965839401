/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/alt-text */
import React, { createContext } from "react"
import { gallery } from "./gallery"
import "./output.css"
import { quickCallButton } from "./quickCallButton"
import { termAndCondition } from "./termAndCondition"
import { text } from "./text"
import { contact } from "./contact"
import { PageFooter } from "./PageFooter"
import RoomCardV2 from "./RoomCardV2"
export const lineLink = "https://page.line.me/nan0932842707?openQrModal=true"
export interface textLang {
  th: string
  en: string
}
export const telIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
    className="w-6 h-6"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
    />
  </svg>
)
const mapIcon = (
  <svg
    style={{ marginTop: "-30px", marginLeft: "30px" }}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-6 h-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
    />
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
    />
  </svg>
)
interface MyState {
  lang: "TH" | "EN"
}
export const LangContext = createContext("EN")
interface MyProps {}
class App extends React.Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props)
    this.state = {
      lang: "EN",
    }
  }
  render() {
    const changeLangButton = () => {
      const getBG = (lang: "TH" | "EN") => {
        if (this.state.lang === lang) return "bg-soft-primary text-white"
      }

      return (
        <div className="absolute right-0 text-lg text-white">
          <button
            className={`rounded-b-2xl px-3 ${getBG("TH")}`}
            onClick={() => this.setState({ lang: "TH" })}
          >
            TH
          </button>
          <button
            className={`rounded-b-2xl px-3 ${getBG("EN")}`}
            onClick={() => this.setState({ lang: "EN" })}
          >
            EN
          </button>
        </div>
      )
    }
    return (
      <div className="bg-softer-primary font-anuphan ">
        <LangContext.Provider value={this.state.lang}>
          <div className=" container mx-auto shadow-2xl ">
            {quickCallButton()}
            {changeLangButton()}
            <div className="bg-primary">
              <div
                className=" bg-primary  bg-fixed  text-center object-center bg-center "
                style={{
                  backgroundImage: "url('optimizeImage/_DSC0203-35.jpeg')",
                  aspectRatio: "auto",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "top",
                }}
              >
                {" "}
                <div>
                  <div className=" shadow-secondary   ">
                    <img
                      src={"image/logo.webp"}
                      className=" w-auto mx-auto  p-10  sm:h-auto "
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="container  mx-auto text-center border-spacing-4 ">
              <h1 className="text-4xl  text-white p-6 bg-soft-primary tracking-widest">
                {text({
                  en: "Prime Location",
                  th: "อยู่ใจกลางเมืองน่าน",
                })}
              </h1>
            </div>
            <div className="grid sm:grid-cols-1 lg:grid-cols-2  ">
              <div>
                <img
                  src="optimizeImage/_DSC0201-33.jpeg"
                  className="bg-cover"
                />
              </div>

              <div className="text-white p-12 text-justify  bg-primary">
                {text({
                  th: "ยินดีต้อนรับสู่โรงแรมเราที่ตั้งอยู่ใจกลางอำเภอเมืองน่าน โรงแรมของเราตั้งอยู่ใกล้ถึงสถานที่สำคัญหลายแห่ง เช่น วัดภูมินทร์และถนนคนเดิน มีความเรียบง่ายและความสะดวกสบายสำหรับแขกทุกท่าน นอกจากนี้ เรายังมีบริการจักรยานและพื้นที่จอดรถที่เพียงพอเพื่อความสะดวกสบายของแขกทุกท่าน",
                  en: "Welcome to our hotel located in the heart of Mueang Nan District. Our hotel is conveniently close to various important places such as Phumin Temple and Walking Street. We pride ourselves on providing simplicity and comfort for all our guests. In addition, we offer bicycle rental services and ample parking space for your convenience.",
                })}
              </div>
            </div>
            <div className="bg-secondary  p-5  w-full pb-16">
              <div className="text-3xl text-center text-primary p-6">
                {text({ th: "ห้องพัก", en: "Our Room" })}{" "}
              </div>
              <div className="flex flex-wrap text-center items-stretch margin-auto">
                {RoomCardV2({
                  roomTypeName: {
                    en: "Standard Double Bed",
                    th: "แสตนดาร์ด เตียงเดี่ยว",
                  },
                  price: 2100,
                  image: "optimizeImage/_DSC0198-31.jpeg",
                  description: {
                    en: `2 People, 1 feet bed`,
                    th: `สำหรับ 2 ท่าน\nเตียงขนาด 6 ฟุค 1 เตียง`,
                  },
                })}
                {RoomCardV2({
                  roomTypeName: {
                    en: "Standard Twin Bed",
                    th: "แสตนดาร์ด เตียงคู่",
                  },
                  price: 2100,
                  image: "optimizeImage/_DSC0173-17.jpeg",
                  description: {
                    th: `สำหรับ 2 ท่าน\nเตียงขนาด 3.5 ฟุค 2 เตียง `,
                    en: `2 People, 6 feet bed`,
                  },
                })}
                {RoomCardV2({
                  roomTypeName: {
                    en: "Standard Family Bed",
                    th: "แสตนดาร์ดครอบครัว",
                  },
                  price: 2500,
                  image: "optimizeImage/2.jpeg",
                  description: {
                    th: `สำหรับ 3 ท่าน\nเตียงขนาด 6 ฟุค 1 เตียง \nเตียงขนาด 3.5 ฟุค 1 เตียง `,
                    en: `3 People, 6 feet bed`,
                  },
                })}
              </div>
            </div>
            {feature()}

            {termAndCondition()}

            {contact()}
            <div className="bg-primary text-white ">
              <div className="text-3xl text-center p-6">
                {text({ en: "Map", th: "แผนที่" })}
              </div>
              <div className=" text-center">
                <div className=" bg-white min-w-full ">
                  <img src="image/map.webp" className="m-auto p-8" />
                  <div className="text-center text-primary pb-4 text-lg">
                    {text({
                      en: "10/8 Nai Wiang, Mueang Nan District, Nan 55000",
                      th: "10/8 ตำบลในเวียง อำเภอเมือง จังหวัดน่าน 55000",
                    })}
                  </div>
                </div>
                <button className="bg-soft-primary p-4  rounded-xl my-12 shadow-lg ">
                  <img src="google-map.svg" className="h-10 inline" />
                  <p className="inline align-middle p-2">
                    <a href="https://goo.gl/maps/aUgNykBV3U8tJFzd8">
                      {text({
                        en: "Open in Google Map",
                        th: "เปิดใน Google Map",
                      })}
                    </a>
                  </p>
                </button>
              </div>
            </div>

            {gallery()}
          </div>
          {PageFooter()}
        </LangContext.Provider>
      </div>
    )
  }
}
export default App

export function feature() {
  return (
    <div className="grid lg:grid-cols-3 grid-cols-1 gap-0 text-center text-white bg-soft-primary static pb-12">
      <div className=" ">
        <img src="/optimizeImage/xxx-2-1024x683.jpeg" />
        <img
          src="optimizeImage/nnkr_sticker-03-1-1024x1024.jpeg"
          className="rounded-full overflow-hidden w-1/3 m-auto  mt-12 mb-12"
        />
        <div className="p-8 ">
          {text({
            en: "Close to walking street",
            th: "ใกล้ถนนคนเดิน",
          })}
        </div>
      </div>

      <div className="h-auto">
        <img src="/optimizeImage/1-1024x683.jpeg" />
        <img
          src="optimizeImage/nnkr_sticker-02.jpeg"
          className="rounded-full overflow-hidden w-1/3 m-auto  mt-12 mb-12"
        />
        <div className="p-8">
          {text({
            en: "Bicycle service for customers free of charge.",
            th: "บริการจักรยาน",
          })}
        </div>
      </div>
      <div className="h-auto ">
        <img src="/optimizeImage/DSC_0504-1-1.jpeg" />
        <img
          src="optimizeImage/nnkr_stickerx-01-768x768.jpeg"
          className="rounded-full overflow-hidden w-1/3 m-auto  mt-12 mb-12"
        />
        <div className="p-8">
          {text({
            en: "Pick up or drop off at the airport, free of charge, 1 way",
            th: "รับหรือส่งสนามบินไม่มีค่าใช้จ่าย 1 เที่ยว",
          })}
        </div>
      </div>
    </div>
  )
}
