/* eslint-disable jsx-a11y/alt-text */
import React from "react"
import { text } from "./text"

export function gallery() {
  const source: { url: string }[] = [
    { url: "optimizeImage/_DSC0149-2.jpeg" },
    { url: "optimizeImage/_DSC0150-3.jpeg" },
    { url: "optimizeImage/_DSC0151-4.jpeg" },
    { url: "optimizeImage/_DSC0155-5.jpeg" },
    { url: "optimizeImage/_DSC0156-6.jpeg" },
    { url: "optimizeImage/_DSC0157-7.jpeg" },
    { url: "optimizeImage/_DSC0193-28.jpeg" },
    { url: "optimizeImage/_DSC0160-9.jpeg" },
    { url: "optimizeImage/_DSC0163-11.jpeg" },
    { url: "optimizeImage/_DSC0165-12.jpeg" },
    { url: "optimizeImage/_DSC0147-1.jpeg" },
    { url: "optimizeImage/_DSC0167-13.jpeg" },
    { url: "optimizeImage/_DSC0168-14.jpeg" },
    { url: "optimizeImage/_DSC0169-15.jpeg" },
    { url: "optimizeImage/_DSC0170-16.jpeg" },
    { url: "optimizeImage/_DSC0173-17.jpeg" },
    { url: "optimizeImage/_DSC0174-18.jpeg" },
    { url: "optimizeImage/_DSC0175-19.jpeg" },
    { url: "optimizeImage/_DSC0176-20.jpeg" },
    { url: "optimizeImage/_DSC0178-21.jpeg" },
    { url: "optimizeImage/_DSC0179-22.jpeg" },
    { url: "optimizeImage/_DSC0182-23.jpeg" },
    { url: "optimizeImage/_DSC0183-24.jpeg" },
    { url: "optimizeImage/_DSC0188-25.jpeg" },
  ]
  return (
    <div className=" bg-soft-primary">
      <div className="text-3xl text-center p-8 text-white ">
        {text({ en: "More Pictures", th: "รูปภาพเพิ่มเติม" })}
      </div>
      <div className="grid lg:3grid-cols-3 grid-cols-2 p-1 ">
        {source.map((row) => (
          <div className="p-1 ">
            <img className="" src={row.url} />
          </div>
        ))}
      </div>
    </div>
  )
}
