import React from "react";
import { LangContext, textLang } from "./App";

export function text(text: textLang)
{
  const textRender = (lang : 'TH' | 'EN') =>{
    if(lang === 'TH')
    return text.th 
    else return text.en
        }
  return <LangContext.Consumer>

  {value => <>{textRender(value as 'TH' | 'EN')}</>}

   

  </LangContext.Consumer>
}


